/* Topbar Styles */

.topbar__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000000;
  width: 100%;
  height: 140px;
}

.topbar__logo {
  margin: 1em;
  width: 12.25%;
  height: 60px;
  background: #c1272d;
}

.topbar__navigation {
  margin: 1em;
  width: 87.75%;
  height: 60px;
  background: #c1272d;
}

/* Hero CTA Styles */

.hero__container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 140px);
  max-height: 1300px;
  background: #cccccc;
}

.hero-cta__container{

  max-width: 2000px;
  width: 78.125%;
  max-height: 832px;
  height: 57.778%;
  background: #c1272d;
  margin-top: -140px;
}

/* Section One Styles */

.section-one__container--outer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b3b3b3;
  width: 100%;
  height: 100vh;
  z-index: 0;
}

.section-one__container--inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b3b3b3;
  width: 78.125%;
  height: 100vh;
  z-index: 0;
}

.section-one__sidebar {
  max-height: 832px;
  height: 57.778%;
  max-width: 350px;
  width: 17.5%;
  background: #c1272d;
}

.section-one__content{
  max-height: 832px;
  height: 57.778%;
  max-width: 1600px;
  width: 80%;
  background: #c1272d;
}

/* Section Two & Three Styles */

.section-two__container, .section-three__container{
  display: flex;
  height: 100vh;
  width: 100vw;
}

.section-two__container--left, .section-two__container--right, .section-three__container--left, .section-three__container--right{
  width: 50%;
  height: 100%;
  max-height: 1440px;
  background: #999999;
}

.section-two__container--right, .section-three__container--left{
  background: #c1272d;
}

/* Section Four Styles */

.section-four__container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  max-height: 1440px;
  background: #666666;
}

.section-four__content{

  height: 10.42%;
  max-height: 150px;
  width: 58.6%;
  max-width: 1500px;
  background: #c1272d;

}

/* Footer Styles */

.footer__container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 810px;
  width: 100vw;
  background: #4d4d4d;
}

.footer__content--container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1498px;
  width: 100%;
}

.footer__content--left{
  width: 26%;
  height: 400px;
  background: #c1272d;
}

.footer__content--right{
  width: 70.5%;
  height: 400px;
  background: #c1272d;
}

.footer__bottom-bar{
  width: 100vw;
  height: 90px;
  background: #000000;
}